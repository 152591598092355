<template>
  <!-- 评价中心 -->
  <div id="app">
    <div class="center-tit">班课评价<span>/Class Comments</span></div>
    <div class="list-con">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="list"
        style="position: relative"
      >
        <img
          v-if="item.cover"
          :src="item.cover"
          class="img"
          style="border: 1px solid #f7f7f7"
        />
        <img
          v-else
          src="@/assets/center/lesson-demo.png"
          class="img"
          style="border: 1px solid #f7f7f7"
          alt=""
        />
        <h2 style="margin-top: 40px">
          {{ item.name }}
        </h2>
        <button
          v-if="!item.comments || item.comments.length == 0"
          type="primary"
          style="
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
          "
          @click="
            currentItem = item;
            dialogVisible = true;
          "
          class="commentButton"
        >
          待评价
        </button>
        <button
          v-else-if="item.comments && item.comments.length == 1"
          type="primary"
          style="
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
          "
          @click="
            currentItem = item;
            dialogVisible = true;
          "
          class="commentButton"
        >
          追加评价
        </button>
        <button
          v-else-if="item.comments && item.comments.length == 2"
          type="primary"
          style="
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
          "
          class="commentButton"
          @click="
            currentItem = item;
            dialogVisible = true;
          "
        >
          查看评价
        </button>
      </div>
    </div>
    <el-dialog
      title="评价"
      :visible.sync="dialogVisible"
      append-to-body
      width="600px"
      :show-close="false"
    >
      <div
        v-for="(comment, index) in currentItem.comments"
        :key="index"
        class="commentText"
      >
        {{ index == 0 ? "评价：" : "追加评价：" }} {{ comment }}
      </div>
      <el-input
        v-if="
          !currentItem.comments ||
          (currentItem.comments && currentItem.comments.length < 2)
        "
        type="textarea"
        v-model="comments"
        rows="5"
        resize="none"
        placeholder="请输入您的评论..."
        class="textarea-comment"
      ></el-input>
      <div slot="footer" style="text-align: center">
        <el-button
          v-if="
            !currentItem.comments ||
            (currentItem.comments && currentItem.comments.length < 2)
          "
          type="primary"
          class="warn"
          @click="publishComments"
          >提交</el-button
        >
        <el-button @click="dialogVisible = false" class="gray">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getCommentsClassList, publishComments } from "../../../service";

export default {
  data() {
    return {
      list: [],
      type: "",
      currentItem: {
        comments: [],
      },
      comments: "",
      dialogVisible: false,
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.getCommentsClassList();
  },
  methods: {
    getCommentsClassList() {
      getCommentsClassList().then((res) => {
        if (res.success) {
          this.list = res.result;
        }
      });
    },
    publishComments() {
      const data = {
        objectId: this.currentItem.id,
        pid: 0,
        type: 0,
        topLevelId: 0,
        content: this.comments,
        visibility: true,
      };
      publishComments(data).then((res) => {
        if (res.success) {
          this.$message.success("评论成功！");
          this.dialogVisible = false;
          this.getCommentsClassList();
          this.comments = "";
        }
      });
    },
  },
};
</script>

<style scoped>
@import "../../../assets/css/center.css";
</style>

<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #fa6400;
}

.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #606266;
}

.c-title {
  text-align: center;
  padding: 20px;
}

.c-title div:nth-child(1) {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.c-title div:nth-child(2) {
  margin-top: 12px;
  font-weight: 400;
  color: #fa6400;
  cursor: pointer;
}

.c-btn {
  display: flex;
  margin-bottom: 20px;
  margin-left: 16px;
  margin-top: 30px;
}

.c-btn div {
  font-size: 14px;
  font-weight: 500;
  padding: 5px 22px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 18px;
  border-radius: 2px;
}

.c-btn div:nth-child(1) {
  color: white;
  background: linear-gradient(to left, #fd1d1d, #fcb045);
  margin-right: 20px;
  cursor: pointer;
}

.c-btn div:nth-child(2) {
  border: 1px solid rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.commentText {
  line-height: 36px;
  font-size: 14px;
}
.commentButton {
  width: 110px;
  height: 28px;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;

  background: url("../../../assets/center/lesson-btn.png");
  background-size: 100% 100%;
  border: none;
}
.warn {
  background-color: #fa6400 !important;
  color: #fff !important;

  background: url("../../../assets/center/lesson-btn.png");
}
.gray {
  color: #909399 !important;
}
.el-dialog {
  border-radius: 8px;
}
.textarea-comment textarea {
  border: 1px solid #ccc;
  resize: none;
  width: 100%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 10px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.65);
  margin: 25px 0;
  border-radius: 5px;
  font-family: "meiruanyahei";
}
.el-dialog__body {
  padding: 0 20px;
}
</style>
